<template>
  <div>
    <p
      style="text-align: center; margin: 40px 0"
      v-if="$store.state.userInfo.mobile"
    >
      您当前企业微信未绑定手机号，请在企业微信设置->账号->手机号进行绑定
    </p>
  </div>
</template>

<script>
import { getUserInfo } from "@/api/api";
export default {
  mounted() {
    let code = this.getQueryVariable("code");
    if (code) {
      getUserInfo({ code: code }).then((res) => {
        let { name, mobile, userid } = res.data.data.user;
        //this.wxJS();
        this.$store.commit("setUserInfo", {
          name: name,
          mobile: mobile,
          userid: userid,
          detail: JSON.stringify(res.data.data.user),
          token: res.data.data.token,
          beforeNowLatestTrip:res.data.data.beforeNowLatestTrip
        });
        if (res.data.data.user.isConfirm == 1) {
          let url = location.href.split("#")[0];
          this.$router.push("/index");
        } else {
          this.$router.push("/info");
        }
      });
    } else {
      if (!this.$store.state.userInfo.token) {
        let uri =
          "https://" +
          document.domain +
          (location.port ? ":" + location.port : "") +
          "/#/login";
        location.replace(
          `http://open.weixin.qq.com/connect/oauth2/authorize?appid=${
            process.env.VUE_APP_APPID
          }&redirect_uri=${encodeURIComponent( 
            uri
          )}&response_type=code&scope=snsapi_base&state=#wechat_redirect`
        );
      }
    }
  },
  methods: {
    getQueryVariable(variable) {
      var query =
        window.location.href.indexOf("?") < 0
          ? window.location.href
          : window.location.href.split("?")[1];
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
  },
  watch: {
    $route(to, from) {
      console.log(to, from);
      if (from.name == "Index" || from.name == "Position") {
        window.close();
      }
    },
  },
};
</script>

<style>
</style>